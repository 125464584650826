#maincontainer{
  min-height: 100vh;
  /* background: linear-gradient(130deg, #141414, 76%, #14ABCF 76%); */
  background-color: blue;
  min-width: 100%;
  display: flex;
}

#leftcontainer{
  width: 50%;
  text-align: center;
  background-color: #2f9bfb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.subcontainers{
  min-height: 100%;
  background: yellow;
}

#rightcontainer{
  width: 50%; 
  background-color: white;
}

#dialogfornext{
  text-align: left;
  /* background-color: #f5f5f5; */
  align-items: left;
  /* top: 30%; */
  margin: 8%;
    color: black;
  width: 80%;
  /* border: 2px solid darkblue; */
  position: relative;
  padding: 15px;
  border-radius: 5px;
}

.dilogbtn{
  text-align: center;
  margin: 10px;
}

#holdpagetwosec{
  text-align: center;
  width: 70%;
  color: #fff;
  margin: 15%;
  position: fixed;
}

.background-image{ 
    height: 720px;
      width: 1390px;
  }
  .logo-image{
    position: relative;
    width: 350px;
    
    
  }
  
  .quota-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -250px;
  }
  
  .success-logo{
   position: fixed;
   top: 20px;
   left: 50%;
   width: 20%;
  }
  
  .success-text{
   position: fixed;
   top: 125px;
   left: 40%;
   width: 50%;
   text-align: center;
  }
  
  .terminate-container{
   position: fixed;
   top: 50%;
   left: 50%;
   margin-top: -50px;
   margin-left: -150px;
  }